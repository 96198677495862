// apiUtils.ts
import { computed } from 'vue';

// Import variables from services to use in components
// import { getResourceUrl, getLevelTechUrl } from '@/services/admin_actions/apiUtils';

// Computed Properties to get the API URLs from environment variables
export const sportTitle = computed(() => import.meta.env.VITE_SPORT_TITLE);

// Computed Properties to get the API URLs from environment variables
export const resourceApiUrl = computed(() => import.meta.env.VITE_RESOURCE_API_URL);
export const levelTechApiUrl = computed(() => import.meta.env.VITE_LEVEL_TECH_API_URL);

// General methods to get the full URL by appending the provided path to each base URL
export const getResourceUrl = (path: string): string => `${resourceApiUrl.value}${path}`;
export const getLevelTechUrl = (path: string): string => `${levelTechApiUrl.value}${path}`;

export const getImageUrl = (img: string) => {
  if (img.startsWith('http')) {
    return img;
  }
  return getResourceUrl('/' + img);
};
