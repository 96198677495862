// apiWebToken.ts

/**
 * Extracts the JWT token from the browser cookies.
 * @returns The JWT token if found, otherwise undefined.
 */
const getJwtToken = (): string | undefined => {
    const jwtToken = localStorage.getItem('tokenV1');
    return jwtToken ?? undefined;
};

export { getJwtToken };
