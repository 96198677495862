import { Capacitor } from '@capacitor/core';

/**
 * Adjusts UI spacing for mobile navigation bar on iOS and Android.
 */
export function adjustMobileNav(): void {
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  const isAndroid = /Android/.test(navigator.userAgent);
  const isMobileApp = Capacitor.isNativePlatform(); // Check if running inside the mobile app

  console.groupCollapsed("Mobile Navigation Adjustment");
  console.log("Device:", isIOS ? "iOS" : isAndroid ? "Android" : "Other");
  console.log("Mobile App:", isMobileApp ? "Yes" : "No");

  // Ensure styles are only applied if it's a mobile app (not just a browser on Android/iOS)
  if (!isMobileApp) {
    console.groupEnd();
    return;
  }

  setTimeout(() => {
    const navigationMenuBar = document.querySelector('.navigation_menu_bar .v-toolbar__content') as HTMLElement;
    const toolbarAppend = document.querySelector('header .v-toolbar__append') as HTMLElement;
    const app = document.getElementById('app') as HTMLElement;

    console.log("Applying adjustments...");
    if (navigationMenuBar) {
      navigationMenuBar.style.height = '95px';
      console.log("Set navigationMenuBar height to 95px");
    }
    if (toolbarAppend) {
      toolbarAppend.style.padding = '20px 0px 0px 0px';
      console.log("Set toolbarAppend padding to 20px 0px 0px 0px");
    }
    if (app) {
      app.style.paddingTop = '46px';
      console.log("Set app paddingTop to 46px");
    }

    console.groupEnd();
  }, 0);
}

/**
 * Detect if the app is running in a mobile app environment (Capacitor).
 */
export const isMobileApp = (): boolean => {
  const result = Capacitor.isNativePlatform();
  // console.log("isMobileApp:", result);
  return result;
};