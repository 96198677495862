// The Axios configuration file axiosConfig.ts is imported.
// This ensures that Axios is configured with CSRF token handling
// and other settings before any component or service uses it.

import App from './App.vue'; // Components
import pinia from '@/store/index'; // Import the Pinia store instance
import ClickTrackerService from '@/services/admin_actions/clickTrackerService'; // Import the Click Tracker Service
import { createApp } from 'vue'; // Composables
import { registerPlugins } from '@/plugins'; // Plugins
import { initializeApiServices } from '@/services/apiServiceCSRF'; // API Services Initialization (including CSRF token fetching)
import '@/styles/_index.scss'; // Global styles
import $ from 'jquery';

//import jquery and the jquery ui and the jquery cropper
(window as any).$ = (window as any).jQuery = $;

const app = createApp(App); // Create the app

    registerPlugins(app);
    app.use(pinia); // Use the Pinia store
    app.mount('#app');

    // Initialize the Click Tracker Service without arguments
const clickTracker = new ClickTrackerService();
clickTracker.init();

initializeApiServices(); // Initialize the API services

