import { useUserStore } from '@/store/userJWTStore'; // Import the Pinia store
import $ from 'jquery'; // Import jQuery

declare global {
  interface JQuery {
    animateRotate(startAngle: any, endAngle: any, duration: any, easing: any, complete: any): JQuery;
    slow_flash(flashes: any, flash_time: any): JQuery;
    submit(): void;
  }
}

export function setupJqueryOverides(baseUrl: string, csrfToken: string) {
  $.ajaxSetup({
    beforeSend: function (xhr: any, options: { url: string }) {
      options.url = baseUrl + options.url.replace('.php', '');
    },
    xhrFields: {
      withCredentials: true,
    },
    crossDomain: true,
    headers: {
      'X-XSRF-TOKEN': csrfToken,
    },
    global: true,
  });

  $.fn.submit = function (): JQuery<HTMLElement> {
    const form = this[0];
    //check if it is a form Element
    if (form && form.tagName === 'FORM') {
      this[0].dispatchEvent(new Event('overrideformsubmit'));
    }
    return this;
  };

  $.fn.slow_flash = function (flashes: any, flash_time: any) {
    let x;
    for (x = 1; x <= flashes; x++)
      this.animate(
        {
          opacity: 0,
        },
        flash_time / 2,
      ).animate(
        {
          opacity: 1,
        },
        flash_time / 2,
      );
    return this;
  };

  // Plug-in for JQuery rotation
  $.fn.animateRotate = function (startAngle : any, endAngle : any, duration : any, easing : any, complete : any) {
    return this.each(function (this: any) {
      var elem = $(this);

      $({
        deg: startAngle,
      }).animate(
        {
          deg: endAngle,
        },
        {
          duration: duration,
          easing: easing,
          step: function (now : any) {
            elem.css({
              '-moz-transform': 'rotate(' + now + 'deg)',
              '-webkit-transform': 'rotate(' + now + 'deg)',
              '-o-transform': 'rotate(' + now + 'deg)',
              '-ms-transform': 'rotate(' + now + 'deg)',
              transform: 'rotate(' + now + 'deg)',
            });
          },
          complete: complete || $.noop,
        },
      );
    });
  };

  // If trim isn't supported (pre-IE9) then add the function here
  if (!String.prototype.trim) {
    (function () {
      var rtrim = /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g;
      String.prototype.trim = function () {
        return this.replace(rtrim, '');
      };
    })();
  }

  // Listen for changes in the cookie, and update the store when it changes | New code using Pinia
  function listenCookieChange() {
    let lastCookie = getCookieValue('SquashlevelsJWT');
    const userStore = useUserStore(); // Initialize the Pinia store

    return function () {
      let currentCookie = getCookieValue('SquashlevelsJWT');

      if (currentCookie !== lastCookie && currentCookie !== undefined) {
        userStore.setToken(currentCookie); // Ensuring currentCookie is defined before passing

        lastCookie = currentCookie;
      }
    };
  }

  function getCookieValue(name: string) {
    const regex = new RegExp(`(^| )${name}=([^;]+)`);
    const match = document.cookie.match(regex);
    if (match) {
      return decodeURIComponent(match[2]);
    }
  }

  window.setInterval(listenCookieChange(), 100);

  (window as any).$ = $;
(window as any).jQuery = $;
}

export function removeEventListeners() {
  const existingScripts = document.querySelectorAll('script.defered_script'); // Query all scripts with 'loaded-script' class
  existingScripts.forEach((script) => {
    document.body.removeChild(script); // Remove the existing script from the body
  });
  if (typeof $ !== 'undefined') {
    $(document).off(); // Remove all event listeners
  }
}
