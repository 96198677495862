import { defineAsyncComponent, watch } from 'vue';
import { defineStore } from 'pinia';
import { axiosLevelTech } from '@/services/axiosConfig';
import { useUserStore } from './userJWTStore'; // Import the userJWTStore for token changes
import { useModalStore } from './modalStore';

// Define an interface for each menu item with optional and required properties
interface SiteMapEntry {
  img?: any; // Optional image URL for the menu item
  mobile?: boolean; // Optional flag indicating mobile-specific visibility
  pos?: string; // Optional position attribute
  title: string; // Required title of the menu item
  href: any; // General type for hyperlink, should be further defined
  highlight?: boolean; // Optional highlight attribute for important items
  style?: string; // Optional style descriptor
  playerid?: string; // Optional player ID for dynamic URLs
  text?: string; // Optional text content for the menu item
}

// Define an interface for the site map structure containing various menu items
interface SiteMap {
  [key: string]: SiteMapEntry | { [key: string]: SiteMapEntry }; // Dynamic keys with nested structure
}

// Define an interface for the overall menu data containing different categories of menu items
interface MenuData {
  site_map: SiteMap; // Structured map of site navigation elements
  top_menu_items: { [key: string]: SiteMapEntry }; // Top menu items collection
  left_menu_items: { [key: string]: SiteMapEntry }; // Left-side menu items collection
  bottom_menu_items: { [key: string]: SiteMapEntry }; // Bottom menu items collection
}

// Define an interface representing the overall state of user data in the store
interface UserDataState {
  menuData: MenuData; // Menu data structured according to MenuData interface
  notifications: any[]; // Array of notifications, type to be further defined as needed
  banners: any[]; // Array of banners, type to be further defined as needed
  jsdefs: any; // JavaScript definitions, type to be further defined as needed
  userInfo: UserInfo | null; // User information, type to be further defined as needed
  triggers: any[]; // Array of triggers, type to be further defined as needed
}

// User info interface matching the structure expected in the state
export interface UserInfo {
  email: string; // The email will be fetched from userName in JwtPayload
  personId: string;
  membership_tiers: {
    is_visitor: boolean;
    is_basic: boolean;
    is_generic: boolean;
    is_ngb: boolean;
    is_trial: boolean;
    is_gold: boolean;
    is_platinum: boolean;
    is_vip: boolean;
    is_developer: boolean;
    is_admin: boolean;
    is_club_admin: boolean;
    is_county_admin: boolean;
    is_results_admin: boolean;
    is_premium: boolean;
  };
}

// Define the store using the 'defineStore' method from Pinia, providing the store ID and its structure
export const useUserDataStore = defineStore('userData', {
  // Define the initial state using a function that returns the state structure
  state: (): UserDataState => ({
    menuData: {
      site_map: {},
      top_menu_items: {},
      left_menu_items: {},
      bottom_menu_items: {},
    },
    notifications: [],
    banners: [],
    jsdefs: {},
    userInfo: null,
    triggers: [],
  }),

  // Actions contain methods for modifying the state
  actions: {
    // Asynchronous action to fetch user data from an API endpoint
    async fetchUserData() {
      try {
        const response = await axiosLevelTech.get('/api/app/Initialise');    
        this.menuData = response.data.data.Menu;
        this.notifications = response.data.data.Notifications;
        this.banners = response.data.data.banners;
        this.jsdefs = response.data.data.JsDefs;
        this.userInfo = {
          ...response.data.data.UserInfo,
          personId: response.data.data.UserInfo.personid || response.data.data.UserInfo.personId, // Normalize personId
        };
        this.triggers = response.data.data.Triggers;
    
        // Grouped logging for personId
        if (this.userInfo && this.userInfo.personId) { // Use normalized 'personId'
        } else {
          console.warn('[API Initialise] No Person ID found in UserInfo.');
        }           
    
        if (response.data.data.Triggers) {
    
          if (response.data.data.Triggers.length > 0 && response.data.data.Triggers[0].id) {
          } else {
            console.warn('No valid Trigger ID found.');
          }
        } else {
          console.warn('No Triggers data found in response.');
        }
    
        const activeTrigger = this.triggers.find(trigger => trigger.active === 1);
        if (activeTrigger) {
          const triggerData = JSON.parse(activeTrigger.trigger_json);
          const modalStore = useModalStore();
                  
          setTimeout(() => {
            modalStore.openModal(
              'help us improve our accuracy!',
              'By reviewing your last match result', // Added description
              defineAsyncComponent(() => import('@/components/AdminComponents/Questionnaires/QuizComponent.vue')),
              {} // Default empty object for props
            );
          }, 5000); // 5 seconds delay on the popup                  
        }        
      } catch (error) {
        console.error('Error Fetching User Data:', error);
      } finally {
      }
    },    
    // Action to mark all notifications as read
    async markNotificationsAsRead() {

      try {
        const response = await axiosLevelTech.post('/api/notification/MarkAsRead');

        if (response.data.status.toLowerCase() === 'success') {

          const updatedNotifications = this.notifications.map((notification) => {
            if (!notification.read) {
              return { ...notification, read: true };
            }
            return notification;
          });

          this.notifications = [...updatedNotifications]; // Trigger reactivity
        } else {
          console.error('Failed to mark notifications as read:', response.data.message);
        }

      } catch (error) {
        console.error('Error calling MarkAsRead API:', error);
      }

    },
  },

  getters: {
    hasAccess(): (allowedRoles: (keyof UserInfo['membership_tiers'])[]) => boolean {
      return (allowedRoles: (keyof UserInfo['membership_tiers'])[]) => {
        if (!this.userInfo || !this.userInfo.membership_tiers) {
          return false;
        }

        const roleCheck = (role: keyof UserInfo['membership_tiers']) => this.userInfo!.membership_tiers[role];
        const hasAccess = allowedRoles.some(roleCheck);

        return hasAccess;
      };
    },
  },
});

// Setup a watcher to react to token changes and refresh user data
const userDataStore = useUserDataStore();
const userStore = useUserStore();

let initialLoad = true; // Track initial load

watch(
  () => userStore.token,
  (newToken, oldToken) => {

    if (initialLoad) {
      initialLoad = false; // Skip fetching on initial load
    } else if (newToken !== oldToken) {
      userDataStore.fetchUserData(); // Trigger only on token change after initial load
    } else {
    }

  },
  { immediate: true },
);
