import { defineStore } from 'pinia';
import { ref, shallowRef } from 'vue';

export const useModalStore = defineStore('modal', () => {
  const isVisible = ref(false);
  const title = ref<string | null>(null);
  const description = ref<string | null>(null);
  const component = shallowRef<any>(null);
  const componentProps = ref<object>({});

  function openModal(newTitle: string, newDescription: string, newComponent: any, props: object = {}) {
    // console.groupCollapsed('[ModalStore] Opening Modal');
    // console.log(`📌 Title: ${newTitle}`);
    // console.log(`📌 Description: ${newDescription}`);
    // console.log('📌 Component:', newComponent);
    // console.log('📌 Props:', props);
    // console.groupEnd();
  
    title.value = newTitle;
    description.value = newDescription; // ✅ Corrected
    component.value = newComponent;
    componentProps.value = props;
    isVisible.value = true;
  }  

  function closeModal() {
    // console.groupCollapsed('[ModalStore] Closing Modal');
    // console.log('❌ Modal is being closed');
    // console.groupEnd();
  
    isVisible.value = false;
    title.value = '';
    description.value = '';
    component.value = null;
    componentProps.value = {};
  }

  return { isVisible, title, description, component, componentProps, openModal, closeModal };
});
