// Composables
import { useUserStore } from '@/store/userJWTStore';
import { createRouter, createWebHistory } from 'vue-router';
import { setSiteMode } from '@/services/mode/siteModeService';
import { removeEventListeners } from '@/classic/JqueryOverrides';
import { isMobileApp } from '@/services/mobile_app/mobileAppServices';
import { startTimer, stopTimer,resetTimers, setPageContext } from '@/services/admin_actions/pageTimerService';

// Imports to manually trigger a modal popup on a specific route
import { defineAsyncComponent } from 'vue';
import { useModalStore } from '@/store/modalStore';

declare let $: any;

const routes = [
  {
    path: '/',
    redirect: '/dashboard',
    component: () => import('@/layouts/DefaultLayout.vue'),
    meta: { RequiresAuth: true },
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: () => import('@/views/SitePages/Dashboard.vue'),
        meta: { hideCommunitiesWidget: true }, // Add this line to hide the communities widget
      },
      {
        path: 'app_login',
        name: 'AppLogin',
        meta: { RequiresAuth: false },
        component: () => import('@/views/SitePages/Login.vue'),
      },
      {
        path: 'skillstesting',
        name: 'SkillsTesting',
        component: () => import('@/views/SitePages/SkillsTesting.vue'),
      },
      {
        path: 'players',
        name: 'Players',
        component: () => import('@/views/SitePages/Players.vue'),
      },
      {
        path: 'info',
        name: 'Info',
        component: () => import('@/views/AdminPages/Info.vue'),
      },
      // {
      //   path: ':catchAll(.*)', // This is a second catch-all route designed to catch any routes that don't match the above routes
      //   name: 'CatchAll', // This catch all needs review
      //   component: () => import('@/views/AdminPages/Error404.vue'),
      // },
      {
        path: 'boxes',
        name: 'Boxes',
        component: () => import('@/views/SitePages/Boxes.vue'),
      },
      {
        path: 'box',
        name: 'Box',
        component: () => import('@/views/SitePages/BoxDetail.vue'),
      },
      {
        path: 'search',
        name: 'Search',
        component: () => import('@/views/SitePages/Search.vue'),
        meta: { hideSearchWidget: true }, // Add this line to hide the search widget
      },
      {
        path: 'invites',
        name: 'Invites',
        component: () => import('@/views/SitePages/Invites.vue'),
      },
      {
        path: 'add_result',
        name: 'AddResult',
        component: () => import('@/views/SitePages/AddResult.vue'),
      },
      {
        path: ':catchAll(.*)',
        name: 'DynamicContent',
        component: () => import('@/views/AdminPages/DynamicContent.vue'),
      },
    ],
  },
  {
    path: '/admin_panel',
    name: 'AdminPanel',
    meta: { RequiresAuth: true },
    component: () => import('@/layouts/DefaultLayout.vue'),
    children: [
      {
        path: '',
        name: 'AdminPanelHome', // Ensure a unique name
        component: () => import('@/views/AdminPages/AdminPanel.vue'),
      },  
      {
        path: 'Classic_Tools',
        name: 'Classic_Tools',
        component: () => import('@/views/ToolsPages/Classic_Tools.vue'),
      },
      {
        path: 'manual_user_merge',
        name: 'Manual_User_Merge',
        component: () => import('@/views/ToolsPages/Manual_User_Merge.vue'),
      },
      {
        path: 'demo',
        name: 'Demo',
        component: () => import('@/views/AdminPages/Demo.vue'),
      },
      {
        path: 'upload_results_file',
        name: 'Upload_Results_File',
        component: () => import('@/views/ToolsPages/Upload_Results_File.vue'),
      },
      {
        path: 'pre_event_override',
        name: 'Pre_Event_Override',
        component: () => import('@/views/ToolsPages/Pre_Event_Override.vue'),
      },
      {
        path: 'import_results',
        name: 'Import_Results',
        component: () => import('@/views/ToolsPages/Import_Results.vue'),
      },
      {
        path: 'variable_print',
        name: 'Variable_Print',
        component: () => import('@/views/ToolsPages/Variable_Print.vue'),
      },
      {
        path: 'banner_config_tool',
        name: 'Banner_Config_Tool',
        component: () => import('@/views/ToolsPages/Banner_Config_Tool.vue'),
      },
      {
        path: 'quiz_config_tool',
        name: 'Quiz_Config_Tool',
        component: () => import('@/views/ToolsPages/Quiz_Config_Tool.vue'),
      },
      {
        path: 'add_new_club',
        name: 'add_new_club',
        component: () => import('@/views/ConfigurationPages/Add_New_Club.vue'),
      },
      {
        path: 'add_new_county',
        name: 'add_new_county',
        component: () => import('@/views/ConfigurationPages/Add_New_County.vue'),
      },
      {
        path: 'add_new_admin',
        name: 'add_new_admin',
        component: () => import('@/views/ConfigurationPages/Add_New_Admin.vue'),
      },
      {
        path: 'add_new_feature_detail',
        name: 'add_new_feature_detail',
        component: () => import('@/views/ConfigurationPages/Add_New_Feature_Detail.vue'),
      },
      {
        path: 'add_new_feature',
        name: 'add_new_feature',
        component: () => import('@/views/ConfigurationPages/Add_New_Feature.vue'),
      },
      {
        path: 'add_new_federation',
        name: 'add_new_federation',
        component: () => import('@/views/ConfigurationPages/Add_New_Federation.vue'),
      },
      {
        path: 'add_new_membership',
        name: 'add_new_membership',
        component: () => import('@/views/ConfigurationPages/Add_New_Membership.vue'),
      },
      {
        path: 'edit_club',
        name: 'edit_club',
        component: () => import('@/views/ConfigurationPages/Edit_Club.vue'),
      },
      {
        path: 'edit_county',
        name: 'edit_county',
        component: () => import('@/views/ConfigurationPages/Edit_County.vue'),
      },
      {
        path: 'add_new_player',
        name: 'add_new_player',
        component: () => import('@/views/ToolsPages/Add_New_Player.vue'),
      },
    ],
  },{
    path: '/federation/:federation/:page',
    name: 'federation',
    beforeEnter: async (to: any, from: any, next: any) => {
      const { federation, page } = to.params;
      
      // Call the API to set the user's site mode
      setSiteMode(federation);
      
      // Redirect to the desired page with mode set in query string
      let query = to.query;
      query.mode = federation;
      next({ path: `/${page}`, query: query});
    },
    component: () => import('@/layouts/DefaultLayout.vue'),
    
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0, behavior: "smooth" };
  },
});

const userStore = useUserStore();
const isApp = isMobileApp();

router.beforeEach((to, from, next) => {
  resetTimers();
  setPageContext(to.fullPath);
  startTimer('router');

  // Ensure the user is logged in on app
  if (isApp && userStore.isLoggedOut && to.meta.RequiresAuth) {
    return next('/app_login');
  }

  removeEventListeners();
  next();
});

router.afterEach(() => {
  stopTimer('router');

  // Only dispatch fallback if not using FetchBodyContent
  if (!document.getElementById('external-content')) {
    setTimeout(() => {
      const event = new Event('body_content_loaded');
      document.dispatchEvent(event);
    }, 100);
  }
});

// Manual method for triggering a modal popup on a specific route
// router.afterEach((to) => {
//   const modalStore = useModalStore();

//   console.groupCollapsed(`[Router] Navigation: ${to.path}`);

//   if (to.path === '/admin_panel/demo') {
//     console.log('✅ Matched /admin_panel/demo - Triggering Modal');
//     modalStore.openModal(
//       'please share your experience with us',
//       defineAsyncComponent(() => import('@/components/AdminComponents/Questionnaires/QuizComponent.vue'))
//     );
//   } else {
//     console.log('ℹ️ No modal trigger for this route');
//   }

//   console.groupEnd();
// });

export default router;
