<template>
  <router-view />
</template>

<script lang="ts" setup>
import { useRoute } from 'vue-router';
import { onMounted, onUnmounted, watch } from 'vue';
import { sportTitle, getResourceUrl } from '@/services/admin_actions/apiUtils';

// Set the canonical link for the page dynamically based on the current route
const route = useRoute();
watch(route, (to) => {
  let canonicalLink = document.querySelector("link[rel='canonical']");
  if (!canonicalLink) {
    canonicalLink = document.createElement("link");
    canonicalLink.setAttribute("rel", "canonical");
    document.head.appendChild(canonicalLink);
  }
  canonicalLink.setAttribute("href", `${window.location.origin}${to.fullPath}`);
}, { immediate: true });

// Helper function to check if an element or its parent has a specific class
const hasClass = (element: HTMLElement, className: string): boolean => {
  while (element) {
    if (element.classList.contains(className)) {
      return true;
    }
    element = element.parentElement as HTMLElement;
  }
  return false;
};

// Function to handle the click event globally
const handleClick = (event: MouseEvent) => {
  const target = event.target as HTMLElement;
  if (hasClass(target, 'help_icon')) {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  } else {
  }
};

// Function to inject JSON-LD structured data for Organization schema
const injectJsonLd = () => {
  const script = document.createElement("script");
  script.type = "application/ld+json";

    // Define the correct description based on the sport
    const sport = sportTitle.value.toLowerCase();
  const description =
    sport === "padel" || sport === "tennis"
      ? "Find your level, track your progress. From the team behind SquashLevels."
      : `${sportTitle.value}Levels has established itself as ${sport}’s premier tool for measuring performance of global ${sport} players and is backed by the World ${sportTitle.value} Federation.`;

  script.text = JSON.stringify({
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": `${sportTitle.value}Levels`,
    "url": window.location.origin,
    "logo": getResourceUrl(`/cdn/${sportTitle.value.toLowerCase()}/site/search-background.jpg`),
    "description": description,
    "image": getResourceUrl(`/cdn/${sportTitle.value.toLowerCase()}/site/search-background.jpg`),
    "sameAs": [
      `https://www.instagram.com/${sportTitle.value.toLowerCase()}levels/`,
      `https://www.facebook.com/${sportTitle.value.toLowerCase()}levels`,
      `https://x.com/${sportTitle.value}Levels`
    ],
    "founder": {
      "@type": "Person",
      "name": "Richard Bickers"
    },
    "contactPoint": {
      "@type": "ContactPoint",
      "email": "support@leveltech.io",
      "contactType": "Customer Service",
      "areaServed": "Worldwide",
      "availableLanguage": "English"
    }
  });

  document.head.appendChild(script);
};

// Setup and teardown of the global event listener
onMounted(() => {
  document.addEventListener('click', handleClick);
  injectJsonLd(); // Inject Organization Schema when the app loads
});

onUnmounted(() => {
  document.removeEventListener('click', handleClick);
});
// End
</script>

<style lang="scss">
@use '@/styles/tech_variables' as *;

/* ---------------------------------------------------------------------------------- */
// Generic Body Styling Declarations (Luke McFarland)

// Set the body background color to light grey
body {
  background: $light-grey;
}

// Temporary Code to Set Site Body Color to Light Grey
.v-main {
  background-color: $light-grey;
}

// Temporary Code to Set Site Body To Display As Flex Column
.v-layout {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}

// Temporary Code to Hide = header, header_placeholder, desktop_sitemap, desktop_social_media, copyright_banner and notifications banners.
#header,
#header_placeholder,
#desktop_sitemap,
#desktop_social_media,
#copyright_banner,
.notification_div,
#bottom_menu_header_placeholder {
  display: none !important;
}

// Temporary Code to Change Background Color
.v-main,
.light_background,
input[type='email'],
#whole_page,
#maya_content_container,
#bottom_menu {
  background-color: $tech_background_color !important;
}

// Temporary code to set the height of the external content to be 100% of the viewport height minus the height of the header and bottom menu
// This stops the "glitch" reported by Jethro
#external-content {
  min-height: calc(100vh - 64px - 100px);
}

// Temporary code to for mobile overrides
@media (min-width: $tablet-screen) {
  #maya {
    height: 100% !important; // Temporary code to set the height of the maya container to 100% on tablet screens or larger
  }

  .normal_width {
    padding-right: 66px !important; // Temporary code to set the padding-right on tablet screens or larger
  }
}

@media (max-width: $tablet-screen) {
  #maya {
    height: unset;
  }
}

// Add Margin to the bottom of the Maya Content Container to prevent it being hidden behind the mobile menu
@media (max-width: $mobile-screen) {
  #maya_content_container {
    margin-bottom: 120px;
  }
}
</style>
