const timers: Record<string, number> = {};
const durations: Record<string, number> = {};
let currentPage = '';

export function setPageContext(page: string) {
  currentPage = page;
}

export function startTimer(label: string) {
  timers[label] = performance.now();
}

export function stopTimer(label: string) {
  if (!timers[label]) return;
  durations[label] = performance.now() - timers[label];
}

export function getTotalTime(): number {
  return Object.values(durations).reduce((sum, time) => sum + time, 0);
}

export function logTimers() {
  console.groupCollapsed(`[⏱️ Page Timer] ${currentPage}`);
  Object.entries(durations).forEach(([label, duration]) => {
    console.log(`✅ ${label}: ${duration.toFixed(1)}ms`);
  });
  console.log(`🔁 Total: ${getTotalTime().toFixed(1)}ms`);
  console.groupEnd();
}

export function resetTimers() {
  Object.keys(timers).forEach(k => delete timers[k]);
  Object.keys(durations).forEach(k => delete durations[k]);
  currentPage = '';
}