/**
 * plugins/vuetify.ts
 *
 * Framework documentation: https://vuetifyjs.com`
 *
 */

// Styles
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';

// Composables
import { createVuetify, type ThemeDefinition } from 'vuetify';

// Squash Theme
export const squashTheme: ThemeDefinition = {
  dark: false,
  colors: {
    primary: '#3A255B', // primary-500
    'primary-600': '#331D55',
    'primary-700': '#281545',
    'primary-800': '#1C093A',
    secondary: '#FF3795', // secondary-500
    highlight: '#4EE7C0', // highlight-500
    action: '#FFCA3B', // action-500

    //Global Colours
    grey: '#EFEFEF', // grey-100
    'grey-100': '#EFEFEF',
    'grey-200': '#E5E5E5',
    'grey-300': '#DDDDDD',
    'grey-400': '#BFBDBD',
    'grey-500': '#8D8C8C',
    'grey-600': '#646464',
    'grey-700': '#363636',
    'grey-800': '#282828',
    'grey-900': '#181818',

    white: '#FFFFFF', // White Colour
    black: '#000000', // Black Colour

    //Alert/General Colours
    info: '#43A5EE', // blue-500
    success: '#6AD26A', // green-500
    warning: '#FFCA3B', // yellow-500
    error: '#FF485D', // err-red-500

    //Hover, Text and Focus Colours
    'primary-hover': '#4A2F74', // primary-400
    'secondary-hover': '#EB016F', // secondary-700
    'highlight-hover': '#74F2D1', // highlight-400
    'action-hover': '#FFD666', // action-400
    'grey-hover': '#E5E5E5', // grey-200
    'primary-focus': '#FF3795', // secondary-500
    'secondary-focus': '#FF3795', // secondary-500
    'highlight-focus': '#FF3795', // secondary-500
    'action-focus': '#FF3795', // secondary-500
    'grey-focus': '#FF3795', // secondary-500
    'primary-text': '#FFFFFF', // white
    'secondary-text': '#FFFFFF', // white
    'highlight-text': '#3A255B', // primary-500
    'action-text': '#3A255B', // primary-500
    'input-label': '#FF3795', // secondary-500 - possibly not used
    'input-hover': '#4EE7C0', // highlight-500
    'input-focus': '#FF3795', // secondary-500
    'grey-text': '#3A255B', // primary-500

    //Misc functional colours
    scrollbar: '#6B568D', // primary-200
  },
};
export const padelTheme: ThemeDefinition = {
  dark: false,
  colors: {
    primary: '#0D0C38', // primary-500
    'primary-600': '#090830',
    'primary-700': '#060529',
    'primary-800': '#03021A',
    secondary: '#D83232', // secondary-500
    highlight: '#2250FF', // highlight-500
    action: '#FFCA3B', // action-500

    //Global Colours
    grey: '#EFEFEF', // grey-100
    'grey-100': '#EFEFEF',
    'grey-200': '#E5E5E5',
    'grey-300': '#DDDDDD',
    'grey-400': '#BFBDBD',
    'grey-500': '#8D8C8C',
    'grey-600': '#646464',
    'grey-700': '#363636',
    'grey-800': '#282828',
    'grey-900': '#181818',

    white: '#FFFFFF', // White Colour
    black: '#000000', // Black Colour

    //Alert/General Colours
    info: '#43A5EE', // blue-500
    success: '#6AD26A', // green-500
    warning: '#FFCA3B', // yellow-500
    error: '#FF485D',

    'primary-hover': '#19184C', // primary-400
    'secondary-hover': '#A51717', // secondary-400
    'highlight-hover': '#456CFF', // highlight-400
    'action-hover': '#FFD666', // action-400
    'grey-hover': '#E5E5E5', // grey-200
    'primary-focus': '#6F74B2', // primary-400
    'secondary-focus': '#FFA1A1', // secondary-400
    'highlight-focus': '#9EB2FF', // highlight-400
    'action-focus': '#FFF0C8', // action-400
    'grey-focus': '#DDDDDD', // grey-
    'primary-text': '#FFFFFF', // primary-400
    'secondary-text': '#FFFFFF', // secondary-400
    'highlight-text': '#FFFFFF', // highlight-400
    'action-text': '#0D0C38', // action-400
    'grey-text': '#0D0C38', // grey-400
    'input-label': '#D83232', // secondary-400
    'input-hover': '#2250FF', // highlight-400
    'input-focus': '#0D0C38', // primary-400

    //Misc functional colours
    scrollbar: '#6F74B2',
  },
};

export const tennisTheme: ThemeDefinition = {
  dark: false,
  colors: {
    primary: '#344737', // purple-500
    'primary-600': '#2C3A2E',
    'primary-700': '#242D25',
    'primary-800': '#1D231E',
    secondary: '#E26139', // teal-500
    highlight: '#E4FF22', // pink-500
    action: '#FFCA3B', // yellow-500

    //Global Colours
    grey: '#EFEFEF', // grey-100
    'grey-100': '#EFEFEF',
    'grey-200': '#E5E5E5',
    'grey-300': '#DDDDDD',
    'grey-400': '#BFBDBD',
    'grey-500': '#8D8C8C',
    'grey-600': '#646464',
    'grey-700': '#363636',
    'grey-800': '#282828',
    'grey-900': '#181818',

    white: '#FFFFFF', // White Colour
    black: '#000000', // Black Color

    //Alert/General Colours
    info: '#43A5EE', // blue-500
    success: '#6AD26A', // green-500
    warning: '#FFCA3B', // yellow-500
    error: '#FF485D', // red-500

    //Hover, Text and Focus Colours
    'primary-hover': '#394C3B', // purple-400
    'secondary-hover': '#B73718', // teal-400
    'highlight-hover': '#F3FF50', // pink-700
    'action-hover': '#FFD666', // yellow-400
    'grey-hover': '#E5E5E5', // grey-200
    'primary-focus': '#344737', // purple-400
    'secondary-focus': '#FF9280', // teal-400
    'highlight-focus': '#FFFFA9', // pink-700
    'action-focus': '#FFF0C8', // yellow-400
    'grey-focus': '#DDDDDD', // grey-
    'primary-text': '#FFFFFF', // purple-400
    'secondary-text': '#FFFFFF', // pink-700
    'highlight-text': '#1D231E', // teal-400
    'action-text': '#1D231E', // yellow-400
    'grey-text': '#1D231E', // yellow
    'input-label': '#344737', // yellow-400
    'input-hover': '#E4FF22', // yellow-400
    'input-focus': '#344737', // yellow-400

    //Misc functional colours
    scrollbar: '#566357',
  },
};

// Racketball Theme still in development
export const racketballTheme: ThemeDefinition = {
  dark: false,
  colors: {
    primary: '#172456', // purple-500
    'primary-600': '#131F47',
    'primary-700': '#0F1A38',
    'primary-800': '#0C162D',
    secondary: '#D83232', // teal-500
    highlight: '#F85596', // pink-500
    action: '#FFCA3B', // yellow-500

    //Global Colours
    grey: '#EFEFEF', // grey-100
    'grey-100': '#EFEFEF',
    'grey-200': '#E5E5E5',
    'grey-300': '#DDDDDD',
    'grey-400': '#BFBDBD',
    'grey-500': '#8D8C8C',
    'grey-600': '#646464',
    'grey-700': '#363636',
    'grey-800': '#282828',
    'grey-900': '#181818',

    white: '#FFFFFF', // define("WHITE", "#FFFFFF");
    black: '#000000', // Black Color

    //Alert/General Colours
    info: '#43A5EE', // blue-500
    success: '#6AD26A', // green-500
    warning: '#FFCA3B', // yellow-500
    error: '#FF485D', // red-500

    //Hover, Text and Focus Colours
    'primary-hover': '#1A2B60', // purple-400
    'secondary-hover': '#A51717', // teal-400
    'highlight-hover': '#456CFF', // pink-700
    'action-hover': '#FFD666', // yellow-400
    'grey-hover': '#E5E5E5', // grey-200
    'primary-focus': '#172456', // purple-400
    'secondary-focus': '#FC90C7', // teal-400
    'highlight-focus': '#9EB2FF', // pink-700
    'action-focus': '#FFF0C8', // yellow-400
    'grey-focus': '#DDDDDD', // grey-
    'primary-text': '#FFFFFF', // purple-400
    'secondary-text': '#FFFFFF', // pink-700
    'highlight-text': '#FFFFFF', // teal-400
    'action-text': '#172456', // yellow-400
    'grey-text': '#172456', // yellow
    'input-label': '#D83232', // yellow-400
    'input-hover': '#F85596', // yellow-400
    'input-focus': '#172456', // yellow-400

    //Misc functional colours
    scrollbar: '#213F7C',
  },
};

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  theme: {
    defaultTheme: 'squashTheme',
    themes: {
      squashTheme,
      padelTheme,
      tennisTheme,
      racketballTheme,
    },
  },
});
