// Fetches CSRF token using Axios; sets it in a cookie for secure API requests.
// Includes error handling.

import { axiosLevelTech } from './axiosConfig';
import { setupJqueryOverides } from '@/classic/JqueryOverrides'; //import jquery overrides

export async function fetchCsrfToken() {
    try {
        if (document.cookie.split('; ').find((row) => row.startsWith('XSRF-TOKEN='))) {
            return;
        }
        const response = await axiosLevelTech.get('/sanctum/csrf-cookie');
        // console.groupCollapsed('CSRF Token Fetch');
        // console.log('Fetched:', response);
        // console.groupEnd();
    } catch (error) {
        // console.groupCollapsed('Error Fetching CSRF Token');
        // console.error(error);
        // console.groupEnd();
        // Consider additional error handling strategies here, like retrying the fetch
    }
}

export async function initializeApiServices() {
    try {
        // Check if the CSRF token is already set in a cookie
        // if not, fetch it and use it.
        let csrfTokenCookie = document.cookie.split('; ').find((row) => row.startsWith('XSRF-TOKEN='));
        let csrfToken = null;
        if (csrfTokenCookie) {
            csrfToken = decodeURIComponent(csrfTokenCookie.split('=')[1]);
        } else {
            await fetchCsrfToken();
            csrfTokenCookie = document.cookie.split('; ').find((row) => row.startsWith('XSRF-TOKEN='));
            if (csrfTokenCookie) {
            csrfToken = decodeURIComponent(csrfTokenCookie!.split('=')[1]);
            }
        }

        const baseUrl = process.env.NODE_ENV === 'production' ? `${import.meta.env.VITE_LEVEL_TECH_API_URL}/api/classic/` : `${import.meta.env.VITE_LOCAL_LEVEL_TECH_API_URL}/api/classic/`;
        
        setupJqueryOverides(baseUrl, csrfToken!);

        // Add any additional API initialization tasks here
    } catch (error) {
        // console.groupCollapsed('Error Initializing API Services');
        // console.error(error);
        // console.groupEnd();
        // Consider how to propagate this error to the user or application
    }
}